import React, { useEffect } from 'react';

import { useAtom } from 'jotai/index';

import { alertBannerAtom, initialAlertBannerProps } from '../../store';
import { NcAlert } from '../NcAlert';

import '@consumer-bootstrap/netcredit.scss';

export const NcAlertBanner = () => {
  const [
    {
      dismissible,
      heading,
      message,
      show,
      variant,
    },
    setAlertBanner,
  ] = useAtom(alertBannerAtom);

  useEffect(() => {
    setAlertBanner(initialAlertBannerProps);
  }, [setAlertBanner]);

  return show && (
    <NcAlert className="nc-alert-banner" dismissible={dismissible} variant={variant}>
      <>
        <p className="banner-heading">{heading}</p>
        <p>{message}</p>
      </>
    </NcAlert>
  );
};
