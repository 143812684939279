/* eslint-disable sort-keys */
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { useOptimizelyFeature } from '@consumer-frontend/nc-api';
import {
  NcContract,
  NcMessageCenter,
  NcMessageDetail,
} from '@consumer-frontend/nc-components';

import { ErrorPage } from './ErrorPage';
import { HomePage } from './HomePage';
import { RootPage } from './RootPage';

const GenericErrorPage = () => <ErrorPage message="Whoops, something went wrong. Please try again." />;

export const App = () => {
  const showReactContract = useOptimizelyFeature('account_home_frontend_contracts');
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootPage />,
      errorElement: GenericErrorPage(),
      children: [
        {
          errorElement: GenericErrorPage(),
          children: [
            {
              path: '',
              index: true,
              element: <HomePage />,
            },
            ...(showReactContract.enabled
              ? [
                {
                  path: '/contract',
                  element: <NcContract />,
                },
              ]
              : []),
            {
              path: '/messages',
              element: <NcMessageCenter />,
            },
            {
              path: '/message-detail',
              element: <NcMessageDetail />,
            },
            {
              path: '*',
              element: <ErrorPage message="Sorry, but the page you're looking for can't be found." />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};
