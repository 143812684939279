import { AxiosError } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { apiEndpoints } from '../../lib';
import { ContractT } from '../../types';

export type UseContractPropsT = {
  accountId: string;
  loanNumber: string;
};

export const useContract = ({ accountId, loanNumber }: UseContractPropsT) => {
  // eslint-disable-next-line @stylistic/object-curly-newline
  const { data, error, isLoading } = useSWRImmutable<ContractT, AxiosError>(
    apiEndpoints.contract(accountId, loanNumber),
  );

  return {
    contract: data,
    error,
    isLoading,
  };
};
