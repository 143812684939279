import { AxiosError } from 'axios';
import useSWR from 'swr';

import { UseContractPropsT } from './useContract';
import { apiEndpoints, blobFetcher } from '../../lib';

export const useContractPDF = ({ accountId, loanNumber }: UseContractPropsT) => {
  // eslint-disable-next-line @stylistic/object-curly-newline
  const { data, error, isLoading } = useSWR<Blob, AxiosError>(
    apiEndpoints.contractPDF(accountId, loanNumber),
    blobFetcher,
  );

  return {
    data,
    error,
    isLoading,
  };
};
