import React, { useState } from 'react';

import { useAtom } from 'jotai/index';

import { useContractPDF } from '@consumer-frontend/nc-api';

import { alertBannerAtom } from '../../store';
import { NcButton } from '../NcButton';

import '@consumer-bootstrap/brands/netcredit/contract/contract-pdf.scss';
import '@consumer-bootstrap/netcredit.scss';

export type NcContractPDFPropsT = {
  accountId: string;
  loanNumber: string;
};
export type NcContractPDFT = React.FC<NcContractPDFPropsT>;

export const NcContractPDF: NcContractPDFT = ({ accountId, loanNumber }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [_, setAlertBanner] = useAtom(alertBannerAtom);
  const {
    data, error, isLoading,
  } = useContractPDF({ accountId, loanNumber });

  const openPDF = () => {
    if (isLoading) {
      return;
    }

    if (error) {
      setAlertBanner({
        dismissible: false,
        heading: 'Error opening PDF.',
        message: (
          <span>Please refresh the page, try again later, or call our Customer Support Team at{' '}
            <a href="tel:8773922014">(877)&nbsp;392-2014</a>.
          </span>
        ),
        show: true,
        variant: 'danger',
      });
    } else if (data?.size) {
      setDisableButton(true);

      const url = URL.createObjectURL(data);

      window.open(url, '_blank', 'noopener,noreferrer');

      setDisableButton(false);
    } else {
      setAlertBanner({
        dismissible: false,
        heading: 'PDF currently unavailable.',
        message: 'It may take up to 12 hours for your contract PDF to become available. Please try again later.',
        show: true,
        variant: 'warning',
      });
    }
  };

  return (
    <div className="contract-pdf-container">
      <NcButton
        variant="outline-primary"
        onClick={openPDF}
        loading={isLoading}
        disabled={disableButton}
      >
        View Contract as PDF
      </NcButton>
      <span className="contract-pdf-span">Download, print or share.</span>
    </div>
  );
};
