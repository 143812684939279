import { useContract } from './useContract';
import { useAccountInfo } from '../useAccountInfo';

export const useAccountAndContract = (accountId: string) => {
  const {
    accountInfo,
    error: accountInfoError,
    isLoading: isAccountInfoLoading,
  } = useAccountInfo(accountId);

  const {
    contract,
    error: contractError,
    isLoading: isContractLoading,
  } = useContract({
    accountId,
    loanNumber: accountInfo?.open_loan_number ? accountInfo.open_loan_number : '',
  });

  const isLoading = isAccountInfoLoading || isContractLoading;
  const error = accountInfoError || contractError;

  return {
    accountInfo, contract, error, isLoading,
  };
};
