import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
  setLogger,
} from '@optimizely/react-sdk';
import { useAtom } from 'jotai';

import { accountIdAtom } from '@consumer-frontend/nc-components';

let optimizely: ReactSDKClient | undefined;
const sdkKey = import.meta.env.VITE_OPTIMIZELY_SDK_KEY;
const DATAFILE_URL = `https://cdn.optimizely.com/datafiles/${sdkKey}.json`;

if (import.meta.env.VITE_ENV !== 'development') {
  const response = await fetch(DATAFILE_URL);
  const datafile = await response.json();

  optimizely = createInstance({
    datafile,
  });

  setLogger(null);
}

export const NcOptimizelyProvider = ({ children }: { children: React.ReactNode; }) => {
  const [accountId] = useAtom(accountIdAtom);

  if (!optimizely) {
    return children;
  }

  return (
    <OptimizelyProvider optimizely={optimizely} user={{ id: accountId }}>
      {children}
    </OptimizelyProvider>
  );
};
