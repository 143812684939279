import React from 'react';

import { useAtom } from 'jotai';

import {
  productType, useAccountAndContract,
} from '@consumer-frontend/nc-api';
import { Container } from '@consumer-frontend/shared-ui';

import { NcContractHTML } from './NcContractHTML';
import { accountIdAtom } from '../../store';
import { NcAlertBanner } from '../NcAlertBanner';
import { NcContractHeader } from '../NcContractHeader/NcContractHeader';
import { NcError } from '../NcError';
import { NcLoadingSpinner } from '../NcLoadingSpinner';

import '@consumer-bootstrap/brands/netcredit/contract/contract-page.scss';

export type NcContractT = React.FC;

export const contractLoadingError = 'There was an issue loading your contract.';

export const NcContract: NcContractT = () => {
  const [accountId] = useAtom(accountIdAtom);
  const {
    accountInfo,
    contract,
    error,
    isLoading,
  } = useAccountAndContract(accountId);

  const contractError = (error || !contract?.body) && !isLoading;

  return (
    <Container className="contract-page d-flex flex-column rounded" hasBoxShadow>
      <h1 className="h2">Contract</h1>
      <NcAlertBanner />
      {isLoading && <NcLoadingSpinner />}
      {contractError && <NcError displaySupportText message={contractLoadingError} />}
      {contract?.body && (
        <>
          {accountInfo && (
            <NcContractHeader
              accountId={accountId}
              loanNumber={accountInfo?.open_loan_number ? accountInfo.open_loan_number : ''}
              productTypeLabel={productType(accountInfo.open_loan_type)}
            />
          )}
          <hr className="contract-divider" />
          <NcContractHTML
            body={contract?.body}
            brandSignature={contract?.brand_signature}
            customerSignature={contract?.customer_signature}
          />
        </>
      )}
    </Container>
  );
};
